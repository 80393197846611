<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnInvoiceCreate" title="Fatura" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newInvoice" />
    <DropdownOptionsInvoice v-if="id" :invoice="invoice" />
    <br />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge v-if="titleStatus != 'Pendente'" :title="'Nº ' + invoice.numberStr" type="primary" size="medium" />
          <Badge :title="titleStatus" :type="typeStatus" size="small" />
        </b-col>
      </b-row>
      <br />
      <InvoiceTotalization :invoiceId="id" />
      <Alert type="info" v-if="invoice.rent">
        <span>
          Fatura vinculada a Locação <b>Nº {{ invoice.rent.number }}</b>
        </span>
      </Alert>
    </div>
    <b-tabs>
      <b-tab title="Dados">
        <CustomerWithCreate :formName="formName" :disabled="disabled" v-model="invoice.customer" />
      </b-tab>
      <b-tab title="Observações">
        <EditorHtml title="Observações" field="observation" :required="false" :disabled="disabled"
          :markFormDirty="false" v-model="invoice.observation" />
      </b-tab>
      <b-tab title="Info">
        <b-row>
          <b-col v-if="titleStatus != 'Pendente'" xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Nº Fatura" field="number" :formName="formName" :required="true" :maxLength="5"
              v-model="invoice.number" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <DateTime title="Data de Emissão" field="registeredDate" format="DD/MM/YYYY" type="date" placeholder
              :formName="formName" :required="true" v-model="invoice.registeredDate" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <div>
              <span class="title">
                Usuário Cadastro <br>
                <div class="div-user">
                  <i class="fa-solid fa-user"></i> {{ invoice.registeredUser }}
                </div>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <br />
    <Molded v-if="invoice.id">
      <ItemsInvoiceList :invoiceId="invoice.id" :status="invoice.status" />
    </Molded>
    <br />
  </div>
</template>

<script>
import InvoiceTotalization from "./InvoiceTotalization.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import DropdownOptionsInvoice from "./DropdownOptionsInvoice.vue";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";

import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";
import ItemsInvoiceList from "../items-invoice/ItemsInvoiceList.vue";

import Invoice from "@/components/modules/billing/invoice/Invoice.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "InvoiceCreateUpdate",
  components: {
    EditorHtml,
    CustomerWithCreate,
    Molded,
    DateTime,
    Button,
    Badge,
    Alert,
    InputNumber,
    CustomerWithCreate,
    ItemsInvoiceList,
    InvoiceTotalization,
    DropdownOptionsInvoice,
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "invoiceCreateUpdate",
      invoice: new Invoice(),
      urlCreate: "/api/v1/billing/invoice/create",
      urlUpdate: "/api/v1/billing/invoice/update",
      urlGetById: "/api/v1/billing/invoice/get-by-id",
      tabIndex: 0,
    };
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    titleStatus() {
      if (this.invoice.status == 1) return "Gerada";
      if (this.invoice.status == 2) return "Cancelada";
      if (this.invoice.status == 3) return "Pendente";
    },
    typeStatus() {
      if (this.invoice.status == 1) return "blue";
      if (this.invoice.status == 2) return "danger";
      if (this.invoice.status == 3) return "warning";
    },
    disabled() {
      if (this.invoice.status == 2) return true;

    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.invoice };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "invoiceUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.invoice };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
        this.getById();
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.invoice.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel" && !this.registerInSelect) {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "customerOnRent") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "updateInvoice") {
          this.getById();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-user {
  margin-top: 10px;
}
</style>
