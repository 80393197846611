export default class Invoice {
    constructor() {
        this.id = "";
        this.number = 0;
        this.numberStr = "";
        this.customer = { id: "", content: "" };
        this.observation = "";
        this.status = 1;
        this.rent = "";
        this.registeredDate = "";
        this.registeredUser = "";
    }
    update(data) {
        this.id = data.id;
        this.number = data.number;
        this.numberStr = data.number.toString();
        this.customer = { id: data.customerSupplier.id, content: data.customerSupplier.person.companyName };
        this.observation = data.observation;
        this.status = data.status;
        this.rent = data.rent;
        this.registeredDate = data.registeredDate;
        this.registeredUser = data.registeredUser;
    }
}